import '../styles/globals.scss';
import 'swiper/swiper.scss';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import React from 'react';
import NextApp from 'next/app';
import Script from 'next/script';
import Prismic from '@prismicio/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import api from '../services/api';
import { Client } from '../prismic-configuration';
import { AuthProvider } from '../contexts/auth';
import { GuideProvider } from '../contexts/guide';
import { MediaProvider } from '../contexts/media';
import { OrderProvider } from '../contexts/order';

import { DiagnosticStickyBanner } from '../components/DiagnosticStickyBanner';

const cleanSwagSentenceToProtocol = (swagSentenceToProtocol) => {
  return swagSentenceToProtocol.map(({ swagSentence, protocol }) => ({
    swagSentence: {
      id: swagSentence.id,
    },
    protocol: {
      id: protocol.id,
      type: protocol.type,
      uid: protocol.uid,
      link_type: protocol.link_type,
    },
  }));
};
export default class MyApp extends NextApp {
  static async getInitialProps(appCtx) {
    const client = Client();
    const bannerHeader = (await client.getSingle('bannerheader', {})) || {};
    let {
      data: { swagSentenceToProtocol },
    } = await client.getSingle('results', {});
    swagSentenceToProtocol = cleanSwagSentenceToProtocol(
      swagSentenceToProtocol,
    );
    const menu = {
      ...(await client.getSingle('menu', {})),
      swagSentenceToProtocol,
    };
    const footer = (await client.getSingle('footer', {})) || {};
    const medias = await Client().query(
      Prismic.Predicates.at('document.type', 'staticmedia'),
    );
    const mediasDict = Object.fromEntries(
      medias.results.map((m) => [m.data.mediaVariableName, m.data.image]),
    );
    return {
      pageProps: {
        bannerHeader: bannerHeader,
        menu: menu,
        footer: footer,
        medias: mediasDict,
      },
    };
  }

  componentDidMount() {
    TagManager.initialize({ gtmId: 'GTM-52SXKQX' });
    ReactGA.initialize('G-SHZ62H3N7T');
    api.post(`api/facebook/register_page_view_event`);
   
    // @ts-ignore
    !function(e){if(!window.pintrk){window.pintrk = function () {
      // @ts-ignore
      window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
        // @ts-ignore
        n=window.pintrk;n.queue=[],n.version="3.0";var
        t=document.createElement("script");t.async=!0,t.src=e;var
        r=document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
    // @ts-ignore
    pintrk('load', '2613266787774');
    // @ts-ignore
    pintrk('page');
    // @ts-ignore
    pintrk('track', 'pagevisit', {
      event_id: 'eventId0001'
    });
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <MediaProvider medias={pageProps.medias}>
          <AuthProvider>
            <OrderProvider>
              <GuideProvider>
                <Component
                  {...pageProps}
                  bannerHeader={pageProps.bannerHeader?.data?.body?.[0]}
                />
                <DiagnosticStickyBanner />
              </GuideProvider>
            </OrderProvider>
          </AuthProvider>
        </MediaProvider>
        <Script
          src="https://cdn.kustomerapp.com/chat-web/widget.js"
          data-kustomer-api-key={process.env.NEXT_PUBLIC_KUSTOMER_DATA_API_KEY}
          strategy="lazyOnload"
          onLoad={() => {
            // @ts-ignore
            Kustomer?.start?.({
              brandId: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID,
            });
          }}
        />
        {/* <!-- Drip --> */}
        <Script type="text/javascript">
          {`var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '5262241';

          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/5262241.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();`}
        </Script>
        {/* <!-- end Drip --> */}
      </>
    );
  }
}
