import Router from 'next/router';
import axios from 'axios';
import Cookies from 'js-cookie';

import { toLoginWithRedirect } from '../utils/urls';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

// multipart/form-data'
const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API,
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'fr-fr',
    'Content-Type': 'application/json',
  },
});

/**
 * Interceptor to handle automatic refresh of JWT token
 */
function createAxiosResponseInterceptor() {
  const interceptor = api.interceptors.response.use(
    (response) => response,
    (error) => {
      // Reject promise if usual error
      if (error.response && error.response.status !== 401) {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      api.interceptors.response.eject(interceptor);

      return api
        .post('api/token/refresh/', {
          refresh: Cookies.get(REFRESH_TOKEN),
        })
        .then((response) => {
          Cookies.set(ACCESS_TOKEN, response.data.access);
          error.response.config.headers.Authorization = `Bearer ${response.data.access}`;
          api.defaults.headers.Authorization = `Bearer ${response.data.access}`;
          return axios(error.response.config);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            Cookies.remove(ACCESS_TOKEN);
            Cookies.remove(REFRESH_TOKEN);
            Router.push(toLoginWithRedirect(Router));
            return Promise.resolve({ data: null });
          }
        })
        .finally(createAxiosResponseInterceptor);
    },
  );
}

createAxiosResponseInterceptor();

export default api;
