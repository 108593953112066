const ACCOUNT_MANAGEMENT = '/compte';
const QUIZZ = '/diagnostic-de-peau';
const REVIEW = '/avis';

export const urls = {
  ACCOUNT_CREATION: `${ACCOUNT_MANAGEMENT}/creer-un-compte`,
  PASSWORD_RESET: `${ACCOUNT_MANAGEMENT}/reinitialisation-du-mot-de-passe`,
  LOGIN: `${ACCOUNT_MANAGEMENT}/connexion`,
  QUIZZ: `${QUIZZ}`,
  START_QUIZZ: `${QUIZZ}/0`,
  HOME: '/',
  PRODUCT: '/produit',
  ACCESSORY: 'accessoire',
  ROUTINE: '/routine',
  PROTOCOL: '/protocole',
  RESULT: '/resultats',
  PRESCRIPTION: '/404',
  COMMANDS: '/commandes',
  CART: '/panier',
  CHECKOUT: '/paiement',
  BLOG: '/journal',
  INGREDIENT: '/ingredient',
  REVIEW: `${REVIEW}`,
  ADD_A_REVIEW: `${REVIEW}/laisser-un-avis`,
  GLOSSARY: '/glossaire',
  SPONSORING: '/code-parrainage',
};

export const URL_KEYWORDS = {
  REDIRECT: 'redirect',
  UID: 'uid',
  TOKEN: 'token',
  FROM_QUIZZ: 'fq',
};

export const toLoginWithRedirect = (router) => {
  return {
    pathname: urls.LOGIN,
    query: { [URL_KEYWORDS.REDIRECT]: router?.asPath ?? urls.RESULT },
  };
};

export const formatQueryParameter = (
  input: string | string[],
  wantedType: 'string' | 'array',
) => {
  if (typeof input === 'string') {
    if (wantedType !== 'string') {
      return [input];
    }
  } else {
    if (wantedType !== 'array') {
      return input?.[0];
    }
  }
  return input;
};

export const withRedirect = (url: string, redirect: string) => {
  return `${url}?redirect=${redirect}`;
};

export default urls;
