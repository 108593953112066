import React, { FC } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import Button from '../button/button';

import { useAuth } from '../../contexts/auth';
import { useSessionStorage } from '../../hooks/useSessionStorage';

import styles from './index.module.scss';

const pagePathsWhereBannerIsHidden: string[] = [
  '/diagnostic-de-peau',
  '/bilan-de-peau',
  '/compte/connexion',
  '/compte/réinitialisation-du-mot-de-passe',
  '/compte/creer-un-compte/',
  '/panier',
  '/paiement',
];

const regexPathsWhereBannerIsHidden = [/\/routine\/retail-typedepeau\d+\/$/];

export const DiagnosticStickyBanner: FC = () => {
  const { loading, isDiagnosticDone } = useAuth();
  const router = useRouter();

  // when we close the banner, we close it for the entire session of the user
  const [isManuallyClosed, setIsManuallyClosed] = useSessionStorage(
    'sticky-banner-closed',
    false,
  );

  const closeBannerHandler = () => {
    setIsManuallyClosed(true);
  };

  const shouldShowBanner =
    // not loading auth context
    !loading &&
    // diagnostic not done (false if non-authenticated user)
    !isDiagnosticDone &&
    // don't display on specific pages
    !pagePathsWhereBannerIsHidden.some((path) =>
      router.asPath.includes(path),
    ) &&
    !regexPathsWhereBannerIsHidden.some((r) => r.test(router.asPath)) &&
    // didn't manually close banner
    !isManuallyClosed;
  return (
    <>
      {shouldShowBanner && (
        <div className={styles.banner__wrapper}>
          <div className={styles.banner__content}>
            <div className={styles.banner__description}>
              <p>
                Faites votre diagnostic de peau, et découvrez votre protocole de
                soins.
              </p>
              <Link href="/diagnostic-de-peau/0">
                <a>
                  <Button
                    content="Commencer le diagnostic"
                    variant
                    className={styles['banner__begin-quizz-button']}
                  />
                </a>
              </Link>
            </div>
            <div
              className={styles['banner__close-button']}
              onClick={closeBannerHandler}
            >
              X
            </div>
          </div>
        </div>
      )}
    </>
  );
};
