import React from 'react';
import styles from './button.module.scss';
import cn from 'classnames';

interface ButtonProps {
  content: string;
  variant?: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
  className?: string;
}

const Button = ({
  content,
  variant,
  onClick,
  disabled = false,
  className,
}: ButtonProps) =>
  content ? (
    <div className={styles.buttonWrapper}>
      <button
        onClick={onClick}
        className={cn(
          styles.button,
          { [styles.variant]: variant, [styles.disabled]: disabled },
          className,
        )}
      >
        {content}
      </button>
    </div>
  ) : null;

export default Button;
