import IIngredient from '../interfaces/ingredient';
import { IPremix, IPremixComposition } from '../interfaces/premix';
import { getRawMaterialsFromIngredient } from './ingredient';

/**
 * Given a premix returns the rawMaterialIdToPercentage
 * of the raw materials of this ingredient
 * @param premix
 * @param ingredientsData
 */
export const getRawMaterialsFromPremix = (
  premix: IPremix,
  ingredientsData: IIngredient[],
): { [rawMaterialId: string]: number } => {
  const rawMaterials = premix?.compositions?.reduce((acc, c) => {
    // Compute ingredients materials
    const ingredientsMaterials = getRawMaterialsFromIngredient(
      ingredientsData.find((i) => i.id === c.ingredient),
    );
    // Apply percentage and add to accumulator
    Object.entries(ingredientsMaterials).forEach(([k, v]) => {
      acc[k] = (acc[k] ?? 0) + (v * parseFloat(c.percentage)) / 100;
    });
    return acc;
  }, {});

  return rawMaterials || {};
};

export const getIngredientsFromPremix = (premix: IPremix): number[] => {
  return premix?.compositions?.map((c) => c.ingredient);
};
