import { IVariant } from '../interfaces/variant';

const AVAILABLE_PACKAGING = {
  POT: 'Pot',
  BOTTLE: 'Flacon pompe',
  DROPPER: 'Flacon pipette',
  ROLLON: 'Roll-on',
  ECO: 'Eco-recharge sans pot',
  BAG: 'Sachet',
  ECOPOT: 'Pot vide & eco-recharge',
  FLASKPATCH: 'Flacon + patch',
};

enum PACKAGING_ORDER {
  ECO,
  BAG,
  FLASK,
  POT,
  BOTTLE,
  DROPPER,
  ROLLON,
  ECOPOT,
  FLASKPATCH,
}

export const packagingComparator = (pA: IVariant, pB: IVariant): number => {
  return PACKAGING_ORDER[pA.packaging] - PACKAGING_ORDER[pB.packaging];
};

export const quantityAndWeightComparator = (
  pA: IVariant,
  pB: IVariant,
): number => {
  const pAQuantity = pA.quantity ?? 0;
  const pBQuantity = pB.quantity ?? 0;

  if (pAQuantity !== pBQuantity) {
    return pAQuantity - pBQuantity;
  } else {
    return pA.weight - pB.weight;
  }
};

export const getAvailableVariantsFromReference = (
  referenceId: number,
  variants: IVariant[],
) => {
  return variants.filter((v) => v.references.some((r) => r.id === referenceId));
};

export const getPackagingName = (packaging: string) => {
  return AVAILABLE_PACKAGING[packaging];
};
