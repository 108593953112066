import React, { createContext, useState, useContext, FC } from 'react';

interface IGuideContext {
  displayGuide: boolean;
  setDisplayGuide: React.Dispatch<React.SetStateAction<boolean>>;
}

const GuideContext = createContext<Partial<IGuideContext>>({});

export const GuideProvider: FC = ({ children }) => {
  const [displayGuide, setDisplayGuide] = useState(false);

  return (
    <GuideContext.Provider value={{ displayGuide, setDisplayGuide }}>
      {children}
    </GuideContext.Provider>
  );
};

export const useGuide = () => useContext(GuideContext);
