import { useEffect, useState } from 'react';

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T | ((val: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window !== 'undefined') {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    }
    return initialValue;
  });

  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    if (typeof window !== 'undefined')
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
  };

  useEffect(() => {
    if (typeof window !== 'undefined')
      if (!window.sessionStorage.getItem(key) && storedValue === initialValue) {
        window.sessionStorage.setItem(key, JSON.stringify(initialValue));
      }
  }, [key, storedValue]);
  return [storedValue, setValue];
}
