import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react';

import api from '../services/api';
import { IOrder } from '../interfaces/order';
import { useAuth } from './auth';
import { IOrderItem } from '../interfaces/order_item';

import IProduct from '../interfaces/product';
import { IProductPageDetailledProduct } from '../interfaces/productPage';
import { IAccessory } from '../interfaces/accessory';
import { AxiosResponse } from 'axios';
import { orderItemRemoveFromCartGTM } from '../utils/order_item';

const LOCAL_STORAGE_ORDER_ID = 'LOCAL_STORAGE_ORDER_ID';

interface IOrderContext {
  order: IOrder;
  setOrder: Dispatch<SetStateAction<IOrder>>;
  addOrderItem: (
    item: Partial<IProduct | IAccessory | IProductPageDetailledProduct>,
    itemType: string,
    payload?: any,
  ) => Promise<AxiosResponse<any>>;
  updateOrderItem: (newItem: IOrderItem) => void;
  findOrderItem: (
    item: Partial<IProduct | IAccessory | IProductPageDetailledProduct>,
    itemType: string,
  ) => IOrderItem;
  removeOrderItem: (
    item: Partial<IProduct | IProductPageDetailledProduct | IAccessory>,
    itemType: OrderItemTypes,
  ) => void;
  numberOfItems: number;
}

export enum OrderItemTypes {
  PRODUCT = 'product',
  PRODUCT_PAGE = 'product_page',
  ACCESSORY = 'accessory',
}

const OrderContext = createContext<Partial<IOrderContext>>({});

export const OrderProvider = ({ children }) => {
  const { user, loading, isAuthenticated } = useAuth();
  const [order, setOrder] = useState<IOrder>(null);

  useEffect(() => {
    if (!loading) {
      if (isAuthenticated) {
        api
          .get('api/orders?status=OPENED')
          .then((response) => setOrder(response.data[0]));
      } else {
        const orderId = localStorage.getItem(LOCAL_STORAGE_ORDER_ID);
        if (orderId) {
          api
            .get(`api/orders/${orderId}`)
            .then((response) => setOrder(response.data))
            .catch((e) => localStorage.removeItem(LOCAL_STORAGE_ORDER_ID));
        }
      }
    }
  }, [loading, isAuthenticated]);

  const addOrderItem = useCallback(
    (
      item: IProduct | IProductPageDetailledProduct | IAccessory,
      itemType: OrderItemTypes,
      payload: any = {},
    ) => {
      const getProductPayload = (product: IProduct) => ({
        product: product?.id,
        price: product?.minimal_price,
        ingredients: user?.ingredients ?? [],
      });

      const getProductPagePayload = (
        productPage: IProductPageDetailledProduct,
      ) => ({
        product_page: productPage.id,
        price: productPage.product.minimal_price,
        ingredients: productPage.benefits.map((b) => b.id),
      });

      const getAccessoryPayload = (accessory: IAccessory) => ({
        accessory: accessory.id,
        price: accessory.price,
      });

      // If no order it means the user is not authenticated
      return (
        !order
          ? api.post('api/orders/create', {}).then((response) => {
              setOrder(response.data);
              localStorage.setItem(LOCAL_STORAGE_ORDER_ID, response.data.id);
              return response.data;
            })
          : Promise.resolve(order)
      ).then((order) => {
        let payloadBuilder: (_: any) => any = getProductPayload;
        if (itemType === OrderItemTypes.PRODUCT_PAGE) {
          payloadBuilder = getProductPagePayload;
        } else if (itemType === OrderItemTypes.ACCESSORY) {
          payloadBuilder = getAccessoryPayload;
        }
        const pushPayload = {
          quantity: 1,
          possible_references: [],
          ingredients: [],
          order: order.id,
          ...payload,
          ...payloadBuilder(item),
        };
        return api.post('api/order-items/', pushPayload).then((response) => {
          setOrder((prevOrder) => ({
            ...prevOrder,
            order_items: [...prevOrder.order_items, response.data],
          }));
          return response;
        });
      });
    },
    [order],
  );

  const findOrderItem = useCallback(
    (
      item: IProduct | IProductPageDetailledProduct | IAccessory,
      itemType: OrderItemTypes,
    ) => {
      if (!order) {
        return undefined;
      }
      const isProductIn = (oi: IOrderItem) =>
        itemType === OrderItemTypes.PRODUCT && item.id === oi.product;
      const isProductPageIn = (oi: IOrderItem) =>
        itemType === OrderItemTypes.PRODUCT_PAGE && item.id === oi.product_page;
      const isAccessoryIn = (oi: IOrderItem) =>
        itemType === OrderItemTypes.ACCESSORY && item.id === oi.accessory;
      const isItemIn = (oi: IOrderItem) =>
        [isProductIn, isProductPageIn, isAccessoryIn].some((cdt) => cdt(oi));
      return order.order_items.find(isItemIn);
    },
    [order],
  );

  const removeOrderItem = useCallback(
    (
      item: IProduct | IProductPageDetailledProduct | IAccessory,
      itemType: OrderItemTypes,
    ) => {
      const orderItem = findOrderItem(item, itemType);
      if (orderItem) {
        orderItemRemoveFromCartGTM(
          // @ts-ignore
          item?.title_fr ?? item?.title,
          null,
          null,
          null,
          orderItem?.price,
        );
        api.delete(`api/order-items/${orderItem.id}`);
        setOrder((previousOrder) => {
          const newOrder = { ...previousOrder };
          newOrder.order_items = newOrder.order_items.filter(
            (oi) => oi.id !== orderItem.id,
          );
          return newOrder;
        });
      }
    },
    [findOrderItem],
  );

  const numberOfItems = order?.order_items?.length;

  const updateOrderItem = useCallback((newItem) => {
    api
      .patch(`api/order-items/${newItem.id}`, newItem)
      .then((response) => response.data)
      .then((newOrderItem) =>
        setOrder((previousOrder) => {
          const newOrder = { ...previousOrder };
          newOrder.order_items = newOrder.order_items.map((x) =>
            x.id === newItem.id ? newOrderItem : x,
          );
          return newOrder;
        }),
      );
  }, []);

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        addOrderItem,
        updateOrderItem,
        findOrderItem,
        removeOrderItem,
        numberOfItems,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
