import Prismic from 'prismic-javascript';
import Link from 'next/link';

import smConfig from './sm.json';
import urls from './utils/urls';

if (!smConfig.apiEndpoint) {
  console.warn(
    "Looks like Slice Machine hasn't been bootstraped already.\nCheck the `Getting Started` section of the README file :)",
  );
}

export const apiEndpoint = smConfig.apiEndpoint;

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken =
  'MC5ZSWVzN1JFQUFDSUFQZ0pX.77-977-9E1Tvv73vv71z77-9QRbvv702Z--_vQLvv73vv73vv71l77-9dO-_vSpvd--_vWZJ77-977-977-9PA';

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolverFromRouter = (router) => (doc) =>
  linkResolver(doc, router);

export const linkResolver = (doc) => {
  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }
  if (doc.type === 'quizzbackground') {
    return `${urls.QUIZZ}/0`;
  }
  if (doc.type === 'routine') {
    return `${urls.ROUTINE}/${doc.uid}`;
  }
  if (doc.type === 'protocole') {
    return `${urls.PROTOCOL}/${doc.uid}`;
  }
  if (doc.type === 'blogpost') {
    return `${urls.BLOG}/${doc.uid}`;
  }
  if (doc.type === 'laisser-un-avis') {
    return urls.ADD_A_REVIEW;
  }
  if (doc.type === 'glossary') {
    return urls.GLOSSARY;
  }
  if (doc.type === 'login') {
    return urls.LOGIN;
  }
  if (doc.type === 'product') {
    return `${urls.PRODUCT}/${doc.data.product.path}`;
  }
  if (doc.type === 'product_page') {
    return `${urls.PRODUCT}/${doc.data.product_page.product.path}/${doc.data.product_page.path}`;
  }
  if (doc.type === 'accessory') {
    return `${urls.PRODUCT}/${urls.ACCESSORY}/${doc.data.accessory.path}`;
  }
  if (doc.type === 'resilier-mot-de-passe') {
    return urls.PASSWORD_RESET;
  }
  if (doc.type === 'creer-un-compte') {
    return urls.ACCOUNT_CREATION;
  }
  if (doc.type === 'parrainage') {
    return urls.HOME;
  }
  return '/';
};

export const customLink = (type, element, content, children, index) => (
  <Link
    key={index}
    href={linkResolver(element.data)}
    as={linkResolver(element.data)}
  >
    <a>{content}</a>
  </Link>
);

export const Router = {
  routes: [{ type: 'page', path: '/:uid' }],
  href: (type) => {
    const route = Router.routes.find((r) => r.type === type);
    return route && route.href;
  },
};

export const Client = (req = null, options = { accessToken }) => {
  return (
    //   Prismic.client(apiEndpoint, Object.assign({ routes: Router.routes, accessToken }, options))
    Prismic.client(apiEndpoint, Object.assign({ accessToken }, options))
  );
};
