import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactElement,
} from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { useRouter } from 'next/router';

import api from '../services/api';
import IUser from '../interfaces/user';
import urls from '../utils/urls';

const LOGIN_ERROR_MESSAGE = 'Email ou mot de passe incorrect';
const ERROR_MESSAGE = 'La connexion a échoué';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

interface IAuthContext {
  isAuthenticated: boolean;
  isDiagnosticDone: boolean;
  user: IUser;
  setUser: (any) => void;
  login: (email: string, password: string, redirect?: string) => Promise<any>;
  loading: boolean;
  logout: () => void;
  fetchUserInformations: () => Promise<IUser>;
}

const AuthContext = createContext<Partial<IAuthContext>>({});

export const AuthProvider = ({ children }: { children: ReactElement }) => {
  const router = useRouter();
  const [user, setUser] = useState<IUser>(null);
  const [loading, setLoading] = useState(true);

  const isDiagnosticDone = user?.ingredients?.length > 0;

  const fetchUserInformations = async (inputToken = null) => {
    const token = inputToken || Cookies.get(ACCESS_TOKEN);
    const decodedJWT: { user_id: number } = jwt_decode(token);
    const { data: user } = await api.get(`api/customer/${decodedJWT.user_id}`);
    const { data: charts } = await api.get(`api/user-scales/`);
    const { data: quizzAnswers } = await api.get(
      `api/customer/${decodedJWT.user_id}/quizz`,
    );

    const newUser: IUser = {
      ...user,
      charts,
      quizzAnswers,
      skinPicture: user.skin_picture,
      pk: decodedJWT.user_id,
    };

    setUser(newUser);
    return newUser;
  };

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get(ACCESS_TOKEN);
      if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        await fetchUserInformations(token);
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  /* Drip identify */
  useEffect(() => {
    if (user) {
      // @ts-ignore
      // _dcq.push([
      //   'identify',
      //   {
      //     email: user.email,
      //     first_name: user.first_name,
      //     last_name: user.last_name,
      //   },
      // ]);
    }
  }, [user]);

  const login = async (
    email: string,
    password: string,
    redirect: string = urls.RESULT,
  ) => {
    try {
      const { data: token } = await api.post('api/login/', {
        email,
        password,
      });
      if (token) {
        Cookies.set(ACCESS_TOKEN, token.access_token);
        Cookies.set(REFRESH_TOKEN, token.refresh_token);
        api.defaults.headers.Authorization = `Bearer ${token.access_token}`;
        const response = await fetchUserInformations(token.access_token);
        if (redirect) {
          router.push(redirect);
        }
        return response;
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        return Promise.reject(new Error(LOGIN_ERROR_MESSAGE));
      }
      return Promise.reject(new Error(ERROR_MESSAGE));
    }
  };

  const logout = () => {
    delete api.defaults.headers.Authorization;
    Cookies.remove(ACCESS_TOKEN);
    Cookies.remove(REFRESH_TOKEN);
    localStorage.clear();
    setUser(null);
    router.reload(); // reload page to clear states
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        setUser,
        login,
        loading,
        logout,
        isDiagnosticDone,
        fetchUserInformations,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
