import React, { createContext, useState, useContext } from 'react';

interface IMediaContext {
  sponsorCodeHeroBackground: any;
  productDisplayBackground: any;
  textureAndPerfumeGuide: any;
  textureAndPerfumeGuideMobile: any;
}

const MediaContext = createContext<Partial<IMediaContext>>({});

interface MediaProviderProps {
  medias: {};
  children: JSX.Element;
}
export const MediaProvider = ({ children, medias }: MediaProviderProps) => {
  return (
    <MediaContext.Provider value={medias}>{children}</MediaContext.Provider>
  );
};

export const useMedia = () => useContext(MediaContext);
