import ReactGA from 'react-ga4';
import IProduct from '../interfaces/product';
import { getProductUrlPath } from './product';
import { IAccessory } from '../interfaces/accessory';
import IProductPage, {
  IProductPageDetailledProduct,
} from '../interfaces/productPage';
import { getProductPageUrlPath } from './productPage';
import { getAccessoryUrlPath } from './accessory';

import { OrderItemTypes } from '../contexts/order';

export const getTitle = (
  product: Partial<IProduct>,
  productPage: Partial<IProductPage> | Partial<IProductPageDetailledProduct>,
  accessory: Partial<IAccessory>,
) => productPage?.title_fr ?? product?.title ?? accessory?.title_fr;

export const getPath = (
  product: Partial<IProduct>,
  productPage: Partial<IProductPage>,
  accessory: Partial<IAccessory>,
) => {
  if (productPage) {
    return getProductPageUrlPath(productPage);
  } else if (product) {
    return getProductUrlPath(product);
  } else if (accessory) {
    return getAccessoryUrlPath(accessory);
  }
};

export const getProductType = (
  product: any,
  productPage: any,
  accessory: any,
): OrderItemTypes => {
  if (productPage) {
    return OrderItemTypes.PRODUCT_PAGE;
  } else if (product) {
    return OrderItemTypes.PRODUCT;
  } else if (accessory) {
    return OrderItemTypes.ACCESSORY;
  }
};

const orderItemCartChange =
  (event: 'add' | 'remove') =>
  (product, variant, reference, perfume, price, quantity = 1) => {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    const gtmEvent =
      event === 'add'
        ? 'EnhancedEcommerce_ProductAdd'
        : 'EnhancedEcommerce_ProductRemove';
    const payload: { [key: string]: any } = {
      event: gtmEvent,
      ecommerce: {
        currencyCode: 'EUR',
      },
    };
    payload.ecommerce[event] = {
      products: [
        {
          name: product,
          format: variant,
          texture: reference,
          perfume: perfume,
          price,
          quantity,
        },
      ],
    };
    // @ts-ignore
    window.dataLayer.push(payload);

    const GAPayload = {
      value: parseFloat(price) * quantity,
      currency: 'EUR',
      items: [
        {
          item_name: product,
          category: variant,
          variant: perfume,
          price: parseFloat(price),
          quantity: quantity,
        },
      ],
    };
    // @ts-ignore
    ReactGA.event(
      event === 'add' ? 'add_to_cart' : 'remove_from_cart',
      GAPayload,
    );
    
    if (event === 'add') {
      // @ts-ignore
      pintrk('track', 'addtocart', {
        event_id: 'eventId0001',
        value: parseFloat(price),
        order_quantity: quantity,
        currency: 'EUR'
      });
    }
  };

export const orderItemAddToCartGTM = orderItemCartChange('add');
export const orderItemRemoveFromCartGTM = orderItemCartChange('remove');
