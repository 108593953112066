import IIngredient from '../interfaces/ingredient';
import IReference from '../interfaces/reference';
import { getRawMaterialsFromIngredient } from './ingredient';
import { getIngredientsFromPremix, getRawMaterialsFromPremix } from './premix';

/* Map texture_fr to ordering */
enum AVAILABLE_TEXTURES_ORDERING {
  fluide,
  'très fluide mate',
  'très fluide',
  'fluide riche',
  poudre,
  onctueuse,
  liquide,
  huileuse,
  aqueuse,
  'légère mate',
  légère,
  riche,
  'très riche',
}

export const compareTexture = (rA: IReference, rB: IReference) => {
  const rATexture: number =
    AVAILABLE_TEXTURES_ORDERING[rA?.texture_fr?.toLowerCase()] ?? -1;
  const rBTexture: number =
    AVAILABLE_TEXTURES_ORDERING[rB?.texture_fr?.toLowerCase()] ?? -1;
  return rATexture - rBTexture;
};

const INGREDIENT_TYPE = {
  EXTRACT: 'EXTRACT',
  DRY_EXTRACT: 'DRY_EXTRACT',
  OIL: 'OIL',
  ESSENCE: 'ESSENCE',
  FLORAL_WATER: 'FLORAL_WATER',
  PETALS: 'PETALS',
  GLYCERIN: 'GLYCERIN',
  PERFUME: 'PERFUME',
  PROTECTOR: 'PROTECTOR',
  EMULSIFIER: 'EMULSIFIER',
  ESTER: 'ESTER',
  POWDER: 'POWDER',
  PREMIX: 'PREMIX',
};

export const NON_CUSTOM_INGREDIENTS = [
  INGREDIENT_TYPE.PERFUME,
  INGREDIENT_TYPE.PROTECTOR,
  INGREDIENT_TYPE.EMULSIFIER,
  INGREDIENT_TYPE.ESTER,
  INGREDIENT_TYPE.POWDER,
  INGREDIENT_TYPE.PREMIX,
];

/**
 * Return raw materials percentage from a reference
 * @param reference
 * @param ingredients
 */
export const getRawMaterialsPercentageFromReference = (
  reference: IReference,
  ingredients: IIngredient[],
  userIngredients: number[],
  perfumeIngredient: number,
): { [Key: string]: number } => {
  if (!reference) {
    return {};
  }
  // Dictionary to keep in memory previous label for a specific key
  let previousRawMaterialsForLabel: {
    [Key: string]: { [Key: string]: number };
  } = {};
  const previousValueForLabel: { [Key: string]: number } = {};

  const result: { [Key: string]: number } = reference.preparations.reduce(
    (acc, p) => {
      const preparationPercentage = parseFloat(p.percentage) / 100;
      let rawMaterials: { [Key: string]: number } = {};
      if (p.ingredient) {
        const matchingIngredient = ingredients.find((i) => {
          if (!(i.id === p.ingredient)) {
            return false;
          } else if (i.ingredient_type === INGREDIENT_TYPE.PERFUME) {
            return i.id === perfumeIngredient;
          } else if (
            !i.visible ||
            NON_CUSTOM_INGREDIENTS.includes(i.ingredient_type)
          ) {
            return true;
          } else {
            return userIngredients?.includes(p.ingredient);
          }
        });
        rawMaterials = getRawMaterialsFromIngredient(matchingIngredient);
        // Try to find if previous label with same key
        if (p.label && matchingIngredient) {
          const labelLetter = p.label[0];
          const labelValue = +p.label.slice(1);
          const previousValue = previousValueForLabel[labelLetter];

          if (previousValue) {
            if (previousValue > labelValue) {
              previousValueForLabel[labelLetter] = labelValue;
              Object.entries(previousRawMaterialsForLabel[labelLetter]).forEach(
                ([k, v]) => {
                  acc[k] -= v * preparationPercentage;
                },
              );
              previousRawMaterialsForLabel[labelLetter] = rawMaterials;
            } else {
              rawMaterials = {};
            }
          } else {
            previousValueForLabel[labelLetter] = labelValue;
            previousRawMaterialsForLabel[labelLetter] = rawMaterials;
          }
        }
      } else if (p.premix) {
        rawMaterials = getRawMaterialsFromPremix(p.premix, ingredients);
      }
      Object.entries(rawMaterials).forEach(
        ([k, v]) => (acc[k] = (acc[k] ?? 0) + v * preparationPercentage),
      );
      return acc;
    },
    {},
  );
  return result;
};

export const getIngredientsFromReference = (
  reference: IReference,
  ingredients: IIngredient[],
  userIngredients: number[],
  perfumeIngredient: number,
): number[] => {
  if (!reference) {
    return [];
  }
  // Dictionary to keep in memory previous label for a specific key
  let previousIngredientForLabel: { [key: string]: number } = {};
  const previousValueForLabel: { [key: string]: number } = {};

  const result: { [key: number]: number } = reference.preparations.reduce(
    (acc, p) => {
      let usedIngredients: number[] = [];
      if (p.ingredient) {
        const matchingIngredientId = ingredients.find((i) => {
          if (i.ingredient_type === INGREDIENT_TYPE.PERFUME) {
            return i.id === p.ingredient && i.id === perfumeIngredient;
          }
          if (
            NON_CUSTOM_INGREDIENTS.includes(i.ingredient_type) ||
            !i.visible
          ) {
            return null;
          }
          if (i.id === p.ingredient) {
            return userIngredients?.includes(p.ingredient);
          }
          return null;
        })?.id;
        usedIngredients = [matchingIngredientId].filter(Boolean);
        if (p.label && matchingIngredientId) {
          const labelLetter = p.label[0];
          const labelValue = +p.label.slice(1);
          const previousValue = previousValueForLabel[labelLetter];

          if (previousValue) {
            if (previousValue > labelValue) {
              previousValueForLabel[labelLetter] = labelValue;
              // remove ingredient
              acc[previousIngredientForLabel[labelLetter]] -= 1;
              previousIngredientForLabel[labelLetter] = matchingIngredientId;
            } else {
              // Do not take this ingredient into account
              usedIngredients = [];
            }
          } else {
            previousValueForLabel[labelLetter] = labelValue;
            previousIngredientForLabel[labelLetter] = matchingIngredientId;
          }
        }
      } else if (p.premix) {
        usedIngredients = getIngredientsFromPremix(p.premix);
      }
      usedIngredients.forEach((i) => (acc[i] = (acc[i] ?? 0) + 1));
      return acc;
    },
    {},
  );
  return Object.entries(result).flatMap(([k, v]) => (v ? +k : []));
};
