import IProduct from '../interfaces/product';
import IProductPage from '../interfaces/productPage';
import IUser from '../interfaces/user';
import { IVariant } from '../interfaces/variant';
import { compareTexture } from './reference';
import urls from './urls';
import {
  getPackagingName,
  packagingComparator,
  quantityAndWeightComparator,
} from './variant';

import uniqBy from 'lodash.uniqby';
import IReference from '../interfaces/reference';
import { IItemImage } from '../interfaces/item_image';
import { Benefit } from '../interfaces/ingredient';

const NO_PERFUME = 'Sans parfum';

const minPriceReducer = (w1: IVariant, w2: IVariant) => {
  const w1price = parseInt(w1.price);
  const w2price = parseInt(w2.price);
  if (w1price === w2price) {
    return w1.id < w2.id ? w1 : w2;
  }
  return w1price < w2price ? w1 : w2;
};

export const getDefaultWeight = (
  product: IProduct,
  productPage?: IProductPage,
): IVariant | null => {
  // Get price with minimal price which is not ECO-RECHARGE
  if (product?.weights?.length > 0) {
    // Ensure the variant is available for the references of the product page
    const productPageAvailableWeight = productPage
      ? product.weights.filter((pw) =>
          pw.references.some((pwr) =>
            productPage.references.some((ppr) => pwr.id === ppr.id),
          ),
        )
      : product.weights;
    // First proposition is something which is not a eco recharge
    const notEcoRechargeableWeights = productPageAvailableWeight.filter(
      (w) => w.packaging !== 'ECO',
    );
    const weightsToConsider =
      notEcoRechargeableWeights.length > 0
        ? notEcoRechargeableWeights
        : productPageAvailableWeight;
    return weightsToConsider.reduce(minPriceReducer, weightsToConsider[0]);
  }
  return null;
};

export const getProductUrlPath = (product: Partial<IProduct>) => {
  return [urls.PRODUCT, product.path].join('/');
};

export const weightToLabel = (weight: Partial<IVariant>): string => {
  if (!weight) {
    return null;
  }
  return weight.quantity
    ? `${getPackagingName(weight.packaging)} ${weight.quantity} gélules`
    : `${getPackagingName(weight.packaging)} ${weight.weight}gr`;
};

export const getDefaultWeightOption = (
  product: IProduct,
  productPage?: IProductPage,
) => {
  const defaultWeight = getDefaultWeight(product, productPage);
  if (defaultWeight) {
    return {
      label: weightToLabel(defaultWeight),
      value: defaultWeight.id,
      price: defaultWeight.price,
    };
  }
  return null;
};

export const getWeightsOptionsFromProduct = (
  product: IProduct,
  availableReferenceIdList: number[],
) => {
  if (!product?.weights) {
    return [];
  }
  return uniqBy(
    [...product.weights]
      .sort((w1, w2) => w1.id - w2.id)
      .sort(quantityAndWeightComparator)
      .sort(packagingComparator)
      .filter((p) =>
        p.references.some((r) =>
          availableReferenceIdList.some((ar) => ar === r.id),
        ),
      )
      .map((w) => ({
        label: weightToLabel(w),
        value: w.id,
        price: w.price,
      })),
    (x) => x.label,
  );
};

export const getPerfumesOptionsFromProduct = (product: IProduct) =>
  uniqBy(
    (
      product?.perfumes?.map((p) => ({
        label: p.name_fr,
        value: p.id,
      })) ?? []
    ).concat([
      {
        label: NO_PERFUME,
        value: null,
      },
    ]),
    (x) => x.label,
  );

export const getTexturesOptionsFromProduct = (
  product: IProduct,
  productPage: IProductPage,
) => {
  const productPageReferences = productPage?.references?.map((r) => r.id) || [];
  const isValidReference = (r: IReference) =>
    productPageReferences?.length === 0 || productPageReferences.includes(r.id);

  return uniqBy(
    product?.textures
      ?.filter(isValidReference)
      .sort(compareTexture)
      .map((t) => ({
        label: t.texture_fr,
        value: t.id,
      })) ?? [],
    (x) => x.label,
  );
};

/**
 * Return all the ingredients which can be contained in a product
 * @param product
 */
export const getIngredientsId = (product: IProduct) => {
  const ingredientsIdFromReferences = product.references.flatMap((r) =>
    r.preparations.flatMap((p) => p.ingredient),
  );
  const ingredientsIdFromPerfume = product.perfumes.flatMap((i) => i.id);
  const ingredientsIdsFromBenefits = product.benefits.flatMap((i) => i.id);
  return uniqBy(
    [
      ...ingredientsIdFromPerfume,
      ...ingredientsIdFromReferences,
      ...ingredientsIdsFromBenefits,
    ],
    (x) => x,
  ).filter(Boolean);
};

export const getUserProductBenefits = (
  product: IProduct,
  user: IUser,
): Benefit[] => {
  const userIngredientsSet = new Set(user?.ingredients);
  return (
    product?.benefits
      ?.filter((b) => userIngredientsSet.has(b.id))
      ?.filter((b) => b.benefit_fr) || []
  );
};

export const getBenefitsFromIngredients = (
  product: IProduct,
  ingredients: number[],
): Benefit[] => {
  const ingredientsSet = new Set(ingredients);
  return uniqBy(
    product?.benefits
      ?.filter((b) => ingredientsSet.has(b.id))
      ?.filter((b) => b.benefit_fr) || [],
    (x: Benefit) => x?.benefit_fr?.toLowerCase(),
  );
};

export const productImagesSorter = (images: IItemImage[]) => {
  const imgComparator = (img1, img2) => {
    return img1.position === 'FIRST' ? -1 : 1;
  };

  return images.sort(imgComparator);
};

export const getProductImage = (images: IItemImage[]) => {
  return images?.find((img) => img.position === 'FIRST');
};
