import IIngredient from '../interfaces/ingredient';
import { urls } from './urls';

export const getRawMaterialsIdFromIngredient = (
  ingredient: IIngredient,
): number[] => {
  return ingredient.compositions.flatMap((c) => c.raw_material);
};

/**
 * Returns the raw materials contained in an ingredient
 * @param ingredients
 */
export const getRawMaterialsIdFromIngredients = (
  ingredients: IIngredient[],
): number[] => {
  return ingredients.flatMap(getRawMaterialsIdFromIngredient);
};

/**
 * Given an ingredient returns the rawMaterialIdToPercentage
 * of the raw materials of this ingredient
 * @param ingredient
 */
export const getRawMaterialsFromIngredient = (
  ingredient: IIngredient,
): { [rawMaterialId: string]: number } => {
  const result =
    ingredient?.compositions?.reduce((acc, c) => {
      acc[c.raw_material] =
        (acc[c.raw_material] ?? 0) + parseFloat(c.percentage) / 100;
      return acc;
    }, {}) || {};
  return result;
};

export const getIngredientUrl = (ingredient: IIngredient) => {
  return [urls?.INGREDIENT, ingredient?.path].join('/');
};
